import React from 'react';
import logo from './logo.svg';
import './App.css';
import MyRoutes from './MyRoutes';

function App() {
  return (
    <div className="Appx">
     <MyRoutes />
    </div>
  );
}

export default App;
