import React from 'react'
import Header from '../components/HomeComponents/Header'
import ManfaatNutriboost from '../components/HomeComponents/ManfaatNutriboost'
import Produk from '../components/HomeComponents/Produk'
import { $primary, $putihSusu } from '../helper/Colors'
import SocialMedia from '../components/HomeComponents/SocialMedia'

const Home = () => {
  return (
    <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#db1515" fillOpacity={1} d="M0,224L60,192C120,160,240,96,360,90.7C480,85,600,139,720,154.7C840,171,960,149,1080,144C1200,139,1320,149,1380,154.7L1440,160L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z" /></svg>
        <Header />
        <ManfaatNutriboost />
        <Produk />
        <SocialMedia />
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#db1515" fillOpacity={1} d="M0,96L48,106.7C96,117,192,139,288,149.3C384,160,480,160,576,149.3C672,139,768,117,864,96C960,75,1056,53,1152,48C1248,43,1344,53,1392,58.7L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z" /></svg>

        <footer style={{ 
            backgroundColor: $primary
        }}>
            <div 
                className='text-center'
                style={{
                    color: $putihSusu
                }}
            >
                { process.env.REACT_APP_NAME}@{new Date().getFullYear()   }
            </div>
        </footer>
    </>
  )
}

export default Home