import React from 'react'

const Header = () => {
  return (
    <div className='container'>
        <div className='text-center'>
            <h1 className='h4'>{process.env.REACT_APP_NAME}</h1>
        </div>
        <div className='text-center mt-5'>
            <p className='h3' style={{ lineHeight: 1.5}}>Hati-hati! dengan minuman ini <br></br> bisa membuatmu  <span className='text-primary-app'>semangat</span> dan <span className='text-primary-app'>fokus</span> dalam <span className='text-primary-app'>bekerja</span>, <span className='text-primary-app'>belajar</span>, dan bikin ketagihan!</p>
        </div>
        <div className='text-center mt-5' data-aos="zoom-in">
            <iframe style={{ width: '70vw'}} height={480} src="https://www.youtube.com/embed/VLxZUK-hd3k" title="Nutriboost – Cerita Vincent Selalu Siap dan Bersemangat" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
        </div>
    </div>
  )
}

export default Header