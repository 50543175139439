import React from 'react'

const Produk = () => {
  return (
        <div className='container mt-5'>
            <div className='row'>
                <div className='col'>
                    <div className='text-center'>
                        <h1 className='h2'>Produk</h1>
                    </div>
                </div>
            </div>
            <div className='row justify-content-evenly align-items-center my-5'>
                <div className='col-md-5 mb-5'>
                    <div className='picture card card-body bg-white shadow'  data-aos='fade-right'>
                        <source media="(max-width: 1199px)" width="558" height="558" />
                        <source media="(min-width: 1200px)" width="928" height="928"/> 
                        
                        <img  src="https://www.coca-cola.com/content/dam/onexp/id/id/brands/nutriboost/nutriboost-resize/Nutriboost_Orange-tetrapack_234x700-desktop.png"  
                            style={{
                                width: '100%',
                            }}                        loading="lazy" class="cmp-image__image" itemprop="contentUrl" alt="NUTRIBOOST Jeruk - Karton 180ml" />
                    </div>
                   
                </div>

                <div className='col-md-5 mb-5' >
                    <div data-aos='fade-left'>
                        <p style={{
                            lineHeight: 2
                        }}>
                            <span className='h4'>NUTRIBOOST Jeruk - Karton 180ml</span>
                            <br></br>
                            NUTRIBOOST jeruk dengan susu asli dan sari buah, mengandung Vitamin B3, B6, E, dan Zink. Hadir dalam kemasan karton isi 180ml.
                        </p>
                    </div>
                </div>
            </div>

            <div className='row justify-content-evenly align-items-center my-5'>
                <div className='col-md-5 mb-5'>
                    <div className='picture card card-body bg-white shadow'  data-aos='fade-right'>
                        <source media="(max-width: 1199px)" width="558" height="558" />
                        <source media="(min-width: 1200px)" width="928" height="928"/> 
                        
                        <img  src="https://www.coca-cola.com/content/dam/onexp/id/id/brands/nutriboost/nutriboost-resize/Nutriboost_Orange-PET-300_234x700-desktop.png"  
                            style={{
                                width: '100%',
                            }}                        loading="lazy" class="cmp-image__image" itemprop="contentUrl" alt="NUTRIBOOST Jeruk - Karton 180ml" />
                    </div>
                   
                </div>

                <div className='col-md-5 mb-5' >
                    <div data-aos='fade-left'>
                        <p style={{
                            lineHeight: 2
                        }}>
                            <span className='h4'>NUTRIBOOST Jeruk - PET 300ml</span>
                            <br></br>
                            NUTRIBOOST jeruk dengan susu asli dan sari buah, mengandung Vitamin B3, B6, E, dan Zink. Hadir dalam kemasan botol 300ml.
                        </p>
                    </div>
                </div>
            </div>

            


            <div className='row justify-content-evenly align-items-center my-5'>
                <div className='col-md-5 mb-5'>
                    <div className='picture card card-body bg-white shadow'  data-aos='fade-right'>
                        <source media="(max-width: 1199px)" width="558" height="558" />
                        <source media="(min-width: 1200px)" width="928" height="928"/> 
                        
                        <img  src="https://www.coca-cola.com/content/dam/onexp/id/id/brands/nutriboost/nutriboost-resize/Nutriboost_Strawberry-tetrapack_234x700-desktop.png"  
                            style={{
                                width: '100%',
                            }}                        loading="lazy" class="cmp-image__image" itemprop="contentUrl" alt="NUTRIBOOST Strawberry - Karton 180ml" />
                    </div>
                   
                </div>

                <div className='col-md-5 mb-5' >
                    <div data-aos='fade-left'>
                        <p style={{
                            lineHeight: 2
                        }}>
                            <span className='h4'>NUTRIBOOST Stroberi - Karton 180ml</span>
                            <br></br>
                            NUTRIBOOST stroberi dengan susu asli dan sari buah, mengandung Vitamin B3, B6, E, dan Zink. Hadir dalam kemasan karton isi 180ml.
                        </p>
                    </div>
                </div>
            </div>

            <div className='row justify-content-evenly align-items-center my-5'>
                <div className='col-md-5 mb-5'>
                    <div className='picture card card-body bg-white shadow'  data-aos='fade-right'>
                        <source media="(max-width: 1199px)" width="558" height="558" />
                        <source media="(min-width: 1200px)" width="928" height="928"/> 
                        
                        <img  src="https://www.coca-cola.com/content/dam/onexp/id/id/brands/nutriboost/nutriboost-resize/Nutriboost_Strawberry-PET-300_234x700-desktop.png"  
                            style={{
                                width: '100%',
                            }}                        loading="lazy" class="cmp-image__image" itemprop="contentUrl" alt="NUTRIBOOST Stroberi - Karton 180ml" />
                    </div>
                   
                </div>

                <div className='col-md-5 mb-5' >
                    <div data-aos='fade-left'>
                        <p style={{
                            lineHeight: 2
                        }}>
                            <span className='h4'>NUTRIBOOST Stroberi - PET 300ml</span>
                            <br></br>
                            NUTRIBOOST stroberi dengan susu asli dan sari buah, mengandung Vitamin B3, B6, E, dan Zink. Hadir dalam kemasan botol 300ml.
                        </p>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Produk