import React from 'react'

const ManfaatNutriboost = () => {
  return (
    <div className='container mt-5' data-aos="zoom-in">
        <div className='row justify-content-center'>
            <div 
                className='col-md-8'
            >
                <p className='text' style={{ lineHeight: 1.7}}>
                    <span className='h2 fw-bold'>Tahukah kamu?</span>
                    <br></br>
                    Sebagai seorang developer, NutriBoost dari Coca-Cola dapat memberikan manfaat yang penting untukmu. Dengan meminum NutriBoost, kamu akan merasakan:
                    <ul>
                        <li>
                            Energi yang meningkat: NutriBoost mengandung vitamin B dan C untuk memberikanmu dorongan energi saat mengembangkan aplikasi.
                        </li>
                        <li>
                            Fungsi otak yang optimal: NutriBoost mengandung vitamin B6 dan B12 untuk menjaga konsentrasi dan kreativitasmu sebagai seorang developer.
                        </li>
                        <li>
                            Ketahanan tubuh yang baik: NutriBoost mengandung vitamin C untuk memperkuat sistem kekebalan tubuhmu, menjaga kesehatan saat bekerja dalam waktu yang panjang.
                        </li>
                    </ul>
                    Dengan meminum NutriBoost, kamu bisa menjaga kesehatan dan meningkatkan performa sebagai seorang developer.
                </p>
            </div>
        </div>
    </div>
  )
}

export default ManfaatNutriboost