import React from 'react'
import { $primary, $putihSusu } from '../../helper/Colors';

const SocialMedia = () => {
  return (
    <>
        <div className='container my-5'>
            <h3 className='h2 text-center'>Perpaduan yang lezat</h3>
            <p  className='fs-5 text-center'>
                Minute Maid Nutriboost pertama kali hadir di Indonesia pada tahun 2013. Minuman ini mengandung susu segar dan jus buah yang merupakan perpaduan yang nikmat.
            </p>
        </div>
        <div className='container my-5'>
            <div style={{ backgroundColor: $primary, padding: 15, borderRadius: 7, }}>
                <div className='row'>
                    <div className='col'>
                        <p style={{ color: $putihSusu}} className='h3'>Ikuti Nutriboost</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='d-flex'>
                            <div style={{ cursor: 'pointer', color: $putihSusu, height: 35, width: 35, borderRadius: 50, border: '2px solid'}} 
                                className='text-center align-items-center justify-content-center d-flex me-3'
                                onClick={() => {
                                    window.open('https://twitter.com/nutriboostid', '_blank');
                                }}
                            >
                                <i className='fab fa-twitter' style={{  fontSize: 20}}></i>
                            </div>
                            <div style={{ cursor: 'pointer', color: $putihSusu, height: 35, width: 35, borderRadius: 50, border: '2px solid'}} 
                                className='text-center align-items-center justify-content-center d-flex me-3'
                                onClick={() => {
                                    window.open('https://www.instagram.com/nutriboostid/', '_blank');
                                }}
                            >
                                <i className='fab fa-instagram' style={{  fontSize: 20}}></i>
                            </div>
                            <div style={{ cursor: 'pointer', color: $putihSusu, height: 35, width: 35, borderRadius: 50, border: '2px solid'}} 
                                className='text-center align-items-center justify-content-center d-flex me-3'
                                onClick={() => {
                                    window.open('https://www.youtube.com/user/nutriboostindonesia', '_blank');
                                }}
                            >
                                <i className='fab fa-youtube' style={{  fontSize: 20}}></i>
                            </div>

                            <div style={{ cursor: 'pointer', color: $putihSusu, height: 35, width: 35, borderRadius: 50, border: '2px solid'}} 
                                className='text-center align-items-center justify-content-center d-flex me-3'
                                onClick={() => {
                                    window.open('https://www.facebook.com/NutriboostID/', '_blank');
                                }}
                            >
                                <i className='fab fa-facebook' style={{  fontSize: 20}}></i>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default SocialMedia